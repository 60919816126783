import Styles from "../styles";
import { FormFull } from "form-full";
import { hooks, masks, validations } from "../../../utils";

import { fonts, SVG } from "../../../config";
import { Grid } from "@material-ui/core";
import { customModal } from "../../../components/modals/utils";
import ptBr from "../../../config/texts/pt-br";
import { CustomText, Input, InputTags } from "../../../components/index";
import { api } from "../../../services";
import React from "react";

interface Visitor{
    id: string,
    placeId: any,
    name: string,
    cpf: string,
    email: string,
    startDate: any,
    endDate: any
}

function ModalVisitorAccess({ item }) {
  const { call } = hooks.useRequest();
  const texts = ptBr.login;
  const [visitors, setVisitors] = React.useState<{
    label: string,
    value: string
  }[]>();
  const [mode, setMode] = React.useState<boolean>(true);
  
  const onSubmit = async (data: any) => {
    if(data.guests?.length>0){
      onSubmit2(data);
    }

    if(data.name&&data.cpf&&data.email){
      data.placeId = item.place.id;
      const date = new Date();
      data.startDate = date.toLocaleDateString();
      date.setHours(date.getHours()+24);
      data.endDate = date.toLocaleDateString();
      call(null, api.saveVisitor(data), (response) => {
        if (response.ok) {
          customModal.close();
          customModal.setInfos(
            "Conceder Acesso a Visitante",
            [
              "Acesso Concedido com sucesso!"
            ],
            {
              label: "Ok, Entendi",
              onClick: () => {
                customModal.close();
              },
            },
            null,
            null,
            false,
            true,
            <Styles.VisitorIcon $customColor />
          );
        }
      });
    }
  };
  
  const onSubmit2 = async (data: any) => {
    if(data.guests.length>0){
      var counter = 0;
      for (let index = 0; index < data.guests.length; index++) {
        const element = data.guests[index];
        if(element.label==="Todos"){
          counter++;
          continue;
        }
        let dataobj = JSON.parse(element.value);
        dataobj.placeId = item.place.id;
        call(null, api.editVisitor(dataobj), (response) => {
          if (response.ok) {
            counter++;
            if(counter==data.guests.length){
              customModal.close();
              customModal.setInfos(
                "Conceder Acesso a Visitante",
                [
                  "Acesso Concedido com sucesso!"
                ],
                {
                  label: "Ok, Entendi",
                  onClick: () => {
                    customModal.close();
                  },
                },
                null,
                null,
                false,
                true,
                <Styles.VisitorIcon $customColor />
              );
            }
          }
        });
      }
    }
  };

  React.useEffect(() => {
    call(null, api.listVisitor(null), (response) => {
      if (response.ok) {
        const teamsOptions:any[] = [];
        for (let index = 0; index < response.data.content.length; index++) {
          const element = response.data.content[index];
          teamsOptions.push({
            label: element.name,
            value: JSON.stringify(element)
          });
        }
        setVisitors(teamsOptions);
      }
    });
  }, []);
  


  
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          minWidth: "clamp(250px, 26vw, 500px)",
          paddingInline: "25px",
          marginBottom: "20px",
        }}
      >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          gap: "12px",
          marginBottom: "25px",
          width: "100%",
        }}
      >
        <button
        style={{
          backgroundColor:mode?"white":"#444444", 
          color:!mode?"white":"black",
          borderColor:"transparent",
          padding:"10px",
          borderRadius:"5px",
        }}
          onClick={()=>{setMode(true)}}
        >
          Cadastrar visitantes
        </button>
        <button
        style={{
          backgroundColor:!mode?"white":"#444444", 
          color:mode?"white":"black",
          borderColor:"transparent",
          padding:"10px",
          borderRadius:"5px",
        }}
          onClick={()=>{setMode(false)}}
        >
          Visitantes já cadastrados
        </button>
      </div>
      {mode?
        <FormFull onSubmit={onSubmit}>
          <CustomText
            style={{ marginBottom: "20px" }}
            fontFamily={fonts.bold}
            fontSize={14 / 8}
            textColor="#B2B2B2">
          Ao continuar e confirmar os dados deste visitante, um guia de cadastro de facial e chave de acesso serão encaminhados para o e-mail informado:
          </CustomText>
          <CustomText
            style={{ marginBottom: "20px" }}
            fontFamily={fonts.bold}
            fontSize={14 / 8}
            textColor="#B2B2B2"
          >
            DADOS BÁSICOS DO VISITANTE
          </CustomText>
          <Grid alignItems="flex-end" container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              
              <Input
                required={"*Campo Obrigatório"}
                name="name"
                defaultValue={item?.name || ""}
                validation={validations.isValidFullname}
                label="Nome Completo"
                white
              />
              
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Input
                required={"*Campo Obrigatório"}
                name="cpf"
                defaultValue={item?.cpf || ""}
                validation={validations.validateCPF}
                maskToSubmit={masks.removeNumberMask}
                mask={masks.CPF}
                label="CPF"
                white
              />
            </Grid>
            <Grid item xs={12} md={6} lg={12}>
              <Input
                required={"*Campo Obrigatório"}
                name="email"
                defaultValue={item?.email || ""}
                validation={validations.isEmailValid}
                label="EMAIL"
                white
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "12px",
              marginTop: "25px",
              width: "100%",
            }}
          >
            <Styles.CancelButton
              fullWidth={false}
              onClick={() => customModal.close()}
            >
              Cancelar
            </Styles.CancelButton>
            <Styles.ConfirmButton fullWidth={false} action="submit">
              Confirmar
            </Styles.ConfirmButton>
          </div>
        </FormFull>:
        <FormFull onSubmit={onSubmit2}>
          <CustomText
            style={{ marginBottom: "20px" }}
            fontFamily={fonts.bold}
            fontSize={14 / 8}
            textColor="#B2B2B2">
          Selecione os visitantes cadastrados na lista abaixo para conceder acesso ao imóvel pelas próximas 24 horas:
          </CustomText>
          <Grid alignItems="flex-end" container spacing={3}>
            <Grid item xs={12} md={6} lg={12}>
              <InputTags
                white
                name="guests"
                label="LISTA DE VISITANTES CADASTRADOS ANTERIORMENTE"
                options={visitors ? visitors : []} 
                defaultValue={[]}/>
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "12px",
              marginTop: "25px",
              width: "100%",
            }}
          >
            <Styles.CancelButton
              fullWidth={false}
              onClick={() => customModal.close()}
            >
              Cancelar
            </Styles.CancelButton>
            <Styles.ConfirmButton fullWidth={false} action="submit">
              Confirmar
            </Styles.ConfirmButton>
          </div>
        </FormFull>
        }
      </div>
    </>
  );
}

export default ModalVisitorAccess;
