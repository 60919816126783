import React from "react";
import { FormFull } from "form-full";
import Styles from "../styles/";
import { Grid } from "@material-ui/core";
import { CustomText, Input, InputAutocomplete, InputDate } from "../../../components/index";
import { masks } from "../../../utils";
import { Label } from "../../../components/inputs/Input";

function Filters({ onSubmit, loading, placeList }) {
    return (
        <FormFull onSubmit={(data) => onSubmit(data)}>
            <Grid container spacing={1}>
                <Grid item xs={6} md={4} lg={2}>
                    <div style={{paddingTop:"9px"}}>
                        <Input name="name" label="NOME COMPLETO" placeholder="Nome Completo" />
                    </div>
                </Grid>
                <Grid item xs={6} md={4} lg={2}>
                    <div style={{paddingTop:"9px"}}>
                        <Input name="cpf" label="CPF" maskToSubmit={masks.removeNumberMask} />
                    </div>
                </Grid>
                <Grid item xs={6} md={4} lg={2}>
                    <div style={{paddingTop:"9px"}}>
                        <Input name="email" label="E-mail" placeholder="E-mail" />
                    </div>
                </Grid>
                <Grid item xs={6} md={4} lg={2}>
                    <div style={{paddingTop:"9px"}}>
                        <InputAutocomplete
                            readOnly={false}
                            disableError                     
                            name="status"
                            label="Status"
                            options={placeList ? placeList : []}
                        />
                    </div>
                </Grid>
                <Grid item xs={6} md={4} lg={2}>
                    <div style={{paddingTop:"9px"}}>
                        <Input name="nhospedes" label="Nº Hóspedes" placeholder="Nº Hóspedes" />
                    </div>
                </Grid>
                
                <Grid container item xs={6} md={4} lg={2}>
                        <Label>
                            DATA DE CADASTRO
                        </Label>
                        <InputDate
                        disableError
                        required={"*Campo Obrigatório"}
                        onChange={(value) => {
                            console.log(value);
                        }}
                        name="startDate"
                        minDate={new Date()}
                        />
                </Grid>
            </Grid>
            <Styles.ButtonContainer>
                <Styles.Clear
                    loading={loading}
                    disabled={loading}
                    fullWidth={false}
                    action="clear"
                >
                    Limpar
                </Styles.Clear>
                <Styles.Submit
                    loading={loading}
                    disabled={loading}
                    fullWidth={false}
                    action="submit"
                >
                    Aplicar Filtros
                </Styles.Submit>
            </Styles.ButtonContainer>
        </FormFull>
    );
}

export default Filters;
