import React from "react";
import { CustomText, Input } from "../../../components/index";
import { Grid } from "@material-ui/core";
import { hooks, masks, validations } from "../../../utils";
import { fonts } from "../../../config";
import Styles from "../styles/Styles";
import { FormFull, FormFullData } from "form-full";
import { customModal } from "../../../components/modals/utils";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import '../../../phone.css'
import { LabelView } from "../../../components/inputs/Input";
import { MyLabel } from "../../registryReservation/components/ModalRegistry";
import { api } from "../../../services";


function ModalRegistry({ data, onSubmit }: { data?: any; onSubmit?: any }) {
  const phone = React.useRef<any>(data?.phone)
  function setPhone(value){
    phone.current = value;
  }
  const { loading, call } = hooks.useRequest();

  const editSP = async (
    values: FormFullData<{
      name:string,
      cnpj:string,
      responsibleName:string,
      phone:string,
      email:string,
    }>
  ) => {
    let submitData = {
      email:values.email,
      name:values.name,
      responsibleName:values.responsibleName,
      cnpj:values.cnpj,
      phone:values.phone
    };
    call(null, api.putServiceProvider(submitData), (response) => {
      if (response.ok) {   
        onSubmit();   
        customModal.close();
      }
    });
  };

  const createSP = async (
    values: FormFullData<
    {
      name:string,
      cnpj:string,
      responsibleName:string,
      phone:string,
      email:string,
    }>
  ) => {
    let submitData = {
      email:values.email,
      name:values.name,
      responsibleName:values.responsibleName,
      cnpj:values.cnpj,
      phone:values.phone
    };
    call(null, api.postServiceProvider(submitData), (response) => {
      if (response.ok) {    
        onSubmit();    
        customModal.close();
      }
    });
  };

  function handleSubmit(dados) {
    dados.phone = phone.current+"";
    if (data) {
      editSP(dados);
    } else {
      createSP(dados);
    }
  }
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          minWidth: "clamp(300px, 20vw, 650px)",
          paddingInline: "25px",
        }}
      >
        <FormFull onSubmit={handleSubmit}>
          <CustomText
            style={{ marginBottom: "20px" }}
            fontFamily={fonts.bold}
            fontSize={14 / 8}
            textColor="#B2B2B2"
          >
            DADOS BÁSICOS DA PRESTADORA DE SERVIÇO:
          </CustomText>
          <Grid alignItems="flex-end" container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <Input
                disableError
                name="name"
                label="Nome da Empresa"
                defaultValue={data?.name || ""}
                validation={validations.isValidFullname}
                required={"*Campo Obrigatório"}
                white
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Input
                disableError
                name="cnpj"
                label="CNPJ"
                defaultValue={data?.cnpj || ""}
                mask={masks.CNPJ}
                validation={validations.validateCNPJ}
                maskToSubmit={masks.removeNumberMask}
                required={"*Campo Obrigatório"}
                white
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Input
                disableError
                name="responsibleName"
                label="NOME RESPONSÁVEL"
                defaultValue={data?.responsibleName || ""}
                validation={validations.isValidFullname}
                required={"*Campo Obrigatório"}
                white
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <LabelView>
                <MyLabel
                  white
                  className={"form-input-label-" + "telefone"?.replace(/\s/g, "")}
                >
                  {"Telefone*"}
                </MyLabel>
              </LabelView>
              <div style={{backgroundColor:"#E9E9E9", height:"48px", alignContent:"center", border:"0px", borderRadius:"5px", padding:"5px"}}>
              <PhoneInputWithCountrySelect
                placeholder="+55 99 99999-9999"
                numberInputProps={{className:"focus:outline-none focus:ring-0",}}
                value={phone.current}
                onChange={setPhone}
              />
              </div>
              {/* 
              <Input
                disableError
                required={"*Campo Obrigatório"}
                mask={masks.inputMaskTELWithDDD}
                name="phone"
                label="Telefone"
                white
                maskToSubmit={masks.removeNumberMask}
              />
              */}
            </Grid>
            <Grid item xs={12} md={6} lg={12}>
              <Input
                disableError
                name="email"
                label="E-mail representante"
                defaultValue={data?.email || ""}
                validation={validations.isEmailValid}
                required={"*Campo Obrigatório"}
                white
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "12px",
              marginTop: "40px",
              width: "100%",
            }}
          >
            <Styles.CancelButton
              fullWidth={false}
              onClick={() => {
                customModal.close();
              }}
            >
              Cancelar
            </Styles.CancelButton>
            <Styles.RegitryButton fullWidth={false} action="submit">
              Confirmar {data ? "Edição" : "Cadastro"}
            </Styles.RegitryButton>
          </div>
        </FormFull>
      </div>
    </>
  );
}

export default ModalRegistry;
