import { create, RequestTransform } from "apisauce";
import caller from "./helpers/caller";

import { SessionStorage } from "../utils";
import createQuery from "./helpers/createQuery";
const apiUrl = process.env.REACT_APP_API;

const api = create({
  baseURL: apiUrl,
  timeout: 500000,
});

api.addRequestTransform((request: any) => {
  const token = SessionStorage.getItem("token");

  if (token) {
    request.headers.authorization = token;
  }
});

api.addMonitor((response: any) => {
  const token = response.headers.authorization;

  if (token) {
    SessionStorage.setItem("token", "Bearer " + token);
  }
});

async function getToken(loginData) {
  return caller(api.post, "/login", null, loginData);
}

async function sendRecoveryPasswordRequest(data) {
  return caller(api.post, `/password/reset/request?email=${data.email}`, null);
}

async function recoveryPasswordValidate(data) {
  return caller(api.post, "/password/reset/code", null, data);
}

async function sendRecoveryPasswordNewPassword(data) {
  return caller(api.put, `/password/reset/confirm`, null, data);
}
// {  headers: { "Access-Control-Allow-Origin": "*" }}
async function getUserData() {
  return caller(api.get, "/user/context", null, null);
}
async function postConfirmNews() {
  return caller(api.patch, `/user/reservations/ack`, null, null);
}
async function putChangePassword(data) {
  return caller(api.post, "/access/redefinition", null, data);
}

async function getPlaces(data) {
  return caller(api.get, createQuery("/place", data), null, null);
}
async function postPlaces(data) {
  return caller(api.post, "/place", null, data);
}

async function updatePlaceAvailability(placeId) {
  return caller(api.patch, `/place/status/available/${placeId}`, null, null);
}

async function updatePlaceBusy(placeId) {
  return caller(api.patch, `/place/status/busy/${placeId}`, null, null);
}

async function updatePlaceClean(placeId) {
  return caller(api.patch, `/place/status/clean/${placeId}`, null, null);
}

async function updatePlaceDirty(placeId) {
  return caller(api.patch, `/place/status/dirty/${placeId}`, null, null);
}

async function updatePlaceUnavailable(placeId) {
  return caller(api.patch, `/place/status/unavailable/${placeId}`, null, null);
}

async function updatePlaceOnMaintenance(placeId) {
  return caller(api.patch, `/place/maintenance/on/${placeId}`, null, null);
}

async function updatePlaceOffMaintenance(placeId) {
  return caller(api.patch, `/place/maintenance/off/${placeId}`, null, null);
}

async function getPlaceData(id) {
  return caller(api.get, `/place/${id}`, null, null);
}

async function getReservation(data) {
  return caller(
    api.get,
    createQuery("/reservation", data) + "&sort=createdAt,desc",
    null,
    null
  );
}

async function postReservation(data) {
  return caller(api.post, "/reservation", null, data);
}
async function getReservationData(id) {
  return caller(api.get, `/reservation/${id}`, null, null);
}

async function patchReservation(data) {
  return caller(api.put, `/reservation`, null, data);
}

async function saveVisitor(data) {
  return caller(api.post, `/visitant`, null, data);
}

async function editVisitor(data) {
  return caller(api.post, `/visitant/reservation`, null, data);
}

async function revokeVisitor(data) {
  return caller(api.patch, `/visitant/reservation/current/cancel`, null, data);
}

async function listVisitor(data) {
  return caller(api.get, `/visitant`, null, data);
}

async function postSendEmail(data) {
  return caller(
    api.post,
    `/reservation/audit/approve/password/reset`,
    null,
    data
  );
}

async function patchReservationApprove(data) {
  return caller(api.patch, `/reservation/audit/approve`, null, data);
}

async function getImageDownload(id) {
  return caller(
    api.get,
    `/image/${id}/download`,
    async (response: any) => {
      async function blobToBase64(blob: Blob): Promise<string> {
        return new Promise<string>((resolve, reject) => {
          const reader = new FileReader();

          reader.onloadend = () => {
            if (reader.result) {
              const base64String = reader.result as string;
              resolve(base64String);
            } else {
              reject(new Error("Failed to convert Blob to Base64."));
            }
          };

          reader.onerror = () => {
            reject(new Error("Error reading Blob."));
          };

          reader.readAsDataURL(blob);
        });
      }
      try {
        const base64Result = await blobToBase64(response.data);
        return { ...response, data: base64Result };
      } catch (error: any) {
        console.error("Error:", error.message);
        return { ...response };
      }
    },
    null,
    {
      responseType: "blob",
    }
  );
}

async function patchReservationRevoke(data) {
  return caller(api.patch, `/reservation/audit/cancel`, null, data);
}

async function patchDeniedAccess(data) {
  return caller(api.patch, `/reservation/audit/reprove`, null, data);
}

async function patchDeniedPhoto(data) {
  return caller(api.patch, `/reservation/audit/reprove/facial`, null, data);
}
async function resendEmailAccess(data) {
  return caller(api.post, `reservation/audit/preaccess/resend`, null, data);
}

interface getOwnersProps {
  page?: number;
  size?: number;
  name?: string;
  cpf?: string;
  email?: string;
}

interface postOwnersProps {
  name: string;
  cpf: string;
  email: string;
  studiosIds: string[];
}

interface putOwnersProps {
  id: string;
  name: string;
  studiosIds: string[];
}

async function getOwners(data: getOwnersProps) {
  return caller(api.get, "/admin", null, data);
}

async function postOwners(data: postOwnersProps) {
  return caller(api.post, "/admin", null, data);
}

async function putOwners(data: putOwnersProps) {
  return caller(api.put, "/admin", null, data);
}

async function deleteOwners(data: { id: string }) {
  return caller(api.delete, `/admin/${data.id}`, null, null);
}

interface getServiceProviderProps {
  page?: number;
  size?: number;
  name?: string;
  cpf?: string;
  email?: string;
}

export interface postServiceProviderProps{
  email:string;
	name:string;
	responsibleName:string;
	cnpj:string;
	phone:string;
}

export interface putServiceProviderProps{
  email:string;
	name:string;
	responsibleName:string;
	cnpj:string;
	phone:string;
}

async function getServiceProvider(data: getServiceProviderProps) {
  return caller(api.get, "/serviceprovider", null, data);
}

async function postServiceProvider(data: postServiceProviderProps) {
  return caller(api.post, "/serviceprovider", null, data);
}

async function putServiceProvider(data: putServiceProviderProps) {
  return caller(api.put, "/serviceprovider", null, data);
}


interface getTenantProps {
  page?: number;
  size?: number;
  name?: string;
  cpf?: string;
  email?: string;
  placeId?: string;
}

export interface postTenantProps{
  name:string,
      cpf:string,
      email:string,
      phone:string,
      startDate:string,
      endDate:string,
      placeIds:any[],
}

export interface putTenantProps{
  name:string,
      cpf:string,
      email:string,
      phone:string,
      startDate:string,
      endDate:string,
      placeIds:any[],
}

async function getTenant(data: getTenantProps) {
  return caller(api.get, "/tenant/reservation", null, data);
}

async function postTenant(data: postTenantProps) {
  return caller(api.post, "/tenant/reservation", null, data);
}

async function putTenant(data: putTenantProps) {
  return caller(api.put, "/tenant/reservation/info", null, data);
}

async function deleteTenant(data: { id: string }) {
  return caller(api.delete, `/tenant/reservation/${data.id}`, null, null);
}

interface getManagersProps {
  page?: number;
  size?: number;
  name?: string;
  cpf?: string;
  email?: string;
}

interface postManagersProps {
  name: string;
  cpf: string;
  email: string;
  studiosIds: string[];
  usersIds: string[];
}

interface putManagersProps {
  id?: string;
  name?: string;
  cpf?: string;
  studiosIds?: string[];
  usersIds?: string[];
}

async function getManagers(data: getManagersProps) {
  return caller(api.get, "/manager", null, data);
}

async function postManagers(data: postManagersProps) {
  return caller(api.post, "/manager", null, data);
}

async function putManagers(data: putManagersProps) {
  return caller(api.put, "/manager", null, data);
}

async function deleteManagers(data: { id: string }) {
  return caller(api.delete, `/manager/${data.id}`, null, null);
}

async function validadeTime(data: getValidTime) {
  return caller(
    api.get,
    createQuery("/reservation/check/conflict", data),
    null,
    null
  );
}

async function validadeCPF(cpf: string) {
  return caller(
    api.get,
    `/renter/find/cpf/${cpf}`,
    null,
    null
  );
}

interface getValidTime {
  placeId: string;
  startDate: string;
  endDate: string;
  reservationId?: string;
}

const apiServices = {
  sendRecoveryPasswordRequest,
  sendRecoveryPasswordNewPassword,
  recoveryPasswordValidate,

  getToken,

  
  saveVisitor,
  editVisitor,
  listVisitor,
  revokeVisitor,

  getUserData,

  putChangePassword,
  getPlaces,
  postPlaces,
  updatePlaceAvailability,
  updatePlaceBusy,
  updatePlaceClean,
  updatePlaceDirty,
  updatePlaceUnavailable,
  updatePlaceOnMaintenance,
  updatePlaceOffMaintenance,
  getPlaceData,
  getReservation,
  postReservation,
  getReservationData,
  postConfirmNews,

  getImageDownload,
  patchReservationApprove,
  patchReservationRevoke,
  postSendEmail,
  patchDeniedAccess,
  patchDeniedPhoto,
  resendEmailAccess,

  getOwners,
  postOwners,
  putOwners,
  deleteOwners,

  getManagers,
  postManagers,
  putManagers,
  deleteManagers,
  patchReservation,
  validadeTime,
  validadeCPF,

  getServiceProvider,
  putServiceProvider,
  postServiceProvider,

  getTenant,
  putTenant,
  postTenant,
  deleteTenant,
};

export default apiServices;
