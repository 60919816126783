import React from "react";
import {
  FormControl,
  TextField,
  FormHelperText,
  Popper,
  TextFieldProps,
} from "@material-ui/core";
import styled from "styled-components";
import { withTheme } from "@material-ui/styles";
import { useFormFull } from "form-full";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { FontStyles, Tag, Tooltip } from "../index";
import { makeStyles } from "@material-ui/core";
import { fonts } from "../../config";

export type TextInputTagsBaseProps = {
  label?: string;
  hideVisualError?: boolean;
  small?: boolean;
  smallBottom?: number;
  alternativeColors?: boolean;
  noOptionsText?: boolean;
  inputStyle?: styled;
  disabled?: boolean;
  required?: string;
  readOnly?: boolean;
  placeholder?: string;
  justNumbers?: boolean;
  options: Array<{
    label: string;
    value: string | number;
  }>;
  onChange?: (event: any, value: any) => void;
  tooltip?: any;
  name: string;
  disableError?: boolean;
  defaultValue: any;
  white?: boolean;
};

const StyledInput = styled(TextField)(
  ({
    theme,
    disabled,
    $inputStyle,
    $error,
    $smallBottom,
    $alternativecolors,
    $height,
  }) => {
    const { spacing, palette: colors } = theme;
    return {
      ...FontStyles.medium14,
      padding: theme.spacing(1.962, 2.5),
      alignItems: "flex-start",
      paddingTop: spacing(2.5),

      multiline: true,

      textOverflow: "ellipsis",
      color: $error ? "red" : theme.palette.text.primary,
      backgroundColor: theme.palette.primary.contrastText,
      borderBottom: "0px",
      borderRadius: theme.spacing(0.6),
      "& .MuiInputBase-input": {
        padding: 0,
        backgroundColor: "transparent",
        boxShadow: "transparent 0px 0px !important",
      },

      "& .MuiInput-input": {
        color: $alternativecolors ? colors.text.secondary : colors.text.primary,
        fontFamily: fonts.medium,
        fontSize: fonts.sizes.regular,

        "&::placeholder": {
          opacity: 0.8,
        },
      },
      "& .MuiIconButton-root": {
        color: colors.text.primary,
      },
      ...$inputStyle,
    };
  }
);

const Label = styled.p(({ theme, $witherror, disabled, white }) => {
  const { palette: colors, spacing } = theme;
  return {
    ...FontStyles.semibold14,
    textTransform: "uppercase",
    padding: 0,
    margin: 0,

    transition: ".2s",
    pointerEvents: "none",
    alignItems: "center",
    display: "flex",
    overflow: "hidden",
    color: $witherror
      ? colors.error.main
      : white
      ? "white"
      : colors.action.selected,

    opacity: disabled && 1,
    marginBottom: spacing(1.5),
  };
});

const ErrorMessage = styled(FormHelperText)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    color: colors.text.secondary,
  };
});

const PopperSelect = styled(Popper)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    "& .MuiAutocomplete-listbox": {
      color: colors.text.primary,
      backgroundColor: colors.primary.contrastText,
      fontFamily: fonts.medium,
      fontSize: fonts.sizes.regular,
      "& li:hover": { backgroundColor: colors.action.focus + 20 },
      '& [aria-selected="true"]': {
        backgroundColor: colors.primary.contrastText + 40,
        borderColor: "transparent",
      },
      "::-webkit-scrollbar-thumb": {
        background: colors.primary.contrastText,
        borderRadius: "15px",
        height: "2px",
      },
    },
  };
});

const LabelView = styled.div(() => {
  return {
    display: "flex",
    alignContent: "center",
    justifyContent: "flex-start",
  };
});

const ShowMoreButton = styled.a(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    ...FontStyles.bold12,
    color: "black",
    marginLeft: spacing(1),
    cursor: "pointer",
    borderBottom: `1px solid black`,
  };
});

const useStyles = makeStyles((theme) => ({
  root: {},
  inputRoot: {
    '&.MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
      paddingBlock: theme.spacing(1.0625),
      paddingInline: theme.spacing(2.5),
      margin: 0,
      "&::before": { display: "none" },
      "&::after": { display: "none" },
    },
  },
}));

function InputTags(props: TextInputTagsBaseProps) {
  const { value, error, onBlur, ref, onChange, ffHandler } =
    useFormFull.field(props);

  const {
    label,
    hideVisualError,
    small,
    smallBottom,
    alternativeColors,
    options,
    inputStyle,
    disabled,
    required,
    noOptionsText,
    tooltip,
    disableError,
    readOnly,
    placeholder = "",
    white,
  } = props;

  let focus = false;
  const classes = useStyles();

  const [show, setShow] = React.useState(false);
  const [all, setAll] = React.useState(false);
  const RemoveDuplicates = (list) => {
    var valueArr = list.map(function (item) {
      return item?.value;
    });
    var isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) !== idx;
    });

    if (isDuplicate) {
      var toRemove = list
        .map((e) => e["value"])
        .map((e, i, final) => final.indexOf(e) !== i && i)
        .filter((obj) => list[obj])
        .map((e) => list[e]["value"]);

      const newList = list.filter(function (el) {
        return el?.value !== toRemove[0];
      });
      return newList;
    } else {
      return list;
    }
  };

  const filterOptionsDefault = createFilterOptions({
    stringify: (option: any) => option?.label,
  });
  function removeAllValue(array) {
    return array.filter((item) => item?.value !== "all");
  }
  const AutocompleteCustom = styled(Autocomplete)(({ theme }) => {});

  return (
    <FormControl fullWidth error={hideVisualError ? undefined : Boolean(error)}>
      <AutocompleteCustom
        filterOptions={filterOptionsDefault}
        noOptionsText={noOptionsText}
        componentsProps={{ disableUnderline: true }}
        classes={classes}
        defaultValue={value?.length > 0 ? value : []}
        value={value?.length > 0 ? value : []}
        multiple
        forcePopupIcon={true}
        id="tags-filled"
        options={options ? [{ value: "all", label: options.length>0?"Todos":"Nenhum Resultado Encontrado" }, ...options] : []}
        disabled={readOnly || disabled}
        disableClearable
        clearOnBlur={false}
        clearOnEscape={false}
        renderOption={(option) => {
          const isSelected = value?.find((i) => i?.value === option?.value);

          return (
            <React.Fragment>
              <div
                style={{
                  display: "flex",

                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#FFFFFF00",
                    border: "2px solid #24272A",
                    borderRadius: "5px",
                    height: "18px",
                    width: "18px",
                    marginRight: "4px",
                    display: "flex",

                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: isSelected ? "#24272A" : "transparent",
                      borderRadius: "2px",
                      height: "8px",
                      width: "8px",
                    }}
                  ></div>
                </div>
                {option?.label}
              </div>
            </React.Fragment>
          );
        }}
        PopperComponent={(props) => {
          return <PopperSelect {...props} />;
        }}
        getOptionLabel={(option) => option?.label || ""}
        onChange={(event, value) => {
          const haveAll = RemoveDuplicates(value)?.find(
            (i) => i?.value === "all"
          );

          if (all && !haveAll) {
            setAll(false);
            onChange(event, []);
          } else if (value === null || value?.length < 1) {
            onChange(event, RemoveDuplicates(value));
          } else if (haveAll && !all) {
            setAll(true);
            onChange(
              event,
              RemoveDuplicates([{ value: "all", label: "Todos" }, ...options])
            );
          } else {
            setAll(false);

            const updatedArray = removeAllValue(value);
            onChange(event, RemoveDuplicates(updatedArray));
          }

          setTimeout(() => {
            onBlur(ffHandler?.testFieldError(props.name));
          }, 10);
        }}
        renderInput={(params) => (
          <>
            <LabelView>
              <Label
                white={white}
                disabled={disabled}
                $withValue={Boolean(value) || value === 0 || focus}
                $witherror={Boolean(error) ? true : undefined}
              >
                {label}{" "}
                {Boolean(error) && label && disableError && required && "*"}
              </Label>
              {tooltip && (
                <Tooltip title={tooltip?.title} tip={tooltip?.content} />
              )}
            </LabelView>

            <StyledInput
              placeholder={placeholder}
              readOnly={readOnly}
              InputProps={{
                disableUnderline: true,
                underline: "none",
              }}
              value={value}
              onFocus={() => (focus = true)}
              disableUnderline={true}
              required={required}
              $inputStyle={inputStyle}
              $alternativecolors={alternativeColors}
              $smallBottom={smallBottom}
              $error={Boolean(error)}
              error={Boolean(error)}
              {...params}
            />
          </>
        )}
        renderTags={(values: any, getTagProps: any) => {
          const selected: any[] = [];
          const clearValues = RemoveDuplicates(values);

          if (clearValues.length > 0) {
            if (!show) {
              for (let i = 0; i < 3; i++) {
                if (
                  Boolean(clearValues[i]) &&
                  clearValues[i]?.value !== "all"
                ) {
                  selected.push(
                    <Tag
                      tabindex={clearValues[i]?.value}
                      label={clearValues[i]?.label}
                      {...getTagProps({ index: i })}
                    />
                  );
                }
              }
              if (clearValues?.length > 3) {
                selected.push(
                  <>
                    <ShowMoreButton onClick={() => setShow(true)}>
                      {`mais ${clearValues?.length - selected?.length}...`}
                    </ShowMoreButton>
                  </>
                );
              }
            } else {
              for (let i = 0; i < clearValues.length; i++) {
                if (clearValues[i]?.value !== "all") {
                  selected.push(
                    <Tag
                      tabindex={clearValues[i]?.value}
                      label={clearValues[i]?.label}
                      {...getTagProps({ index: i })}
                    />
                  );
                }
              }
              selected.push(
                <>
                  <ShowMoreButton onClick={() => setShow(false)}>
                    mostrar menos
                  </ShowMoreButton>
                </>
              );
            }
          } else {
            return null;
          }

          return selected.map((option) => option);
        }}
      />
      {disableError ? null : (
        <ErrorMessage error={Boolean(error)}>
          {Boolean(error) ? error : " "}
        </ErrorMessage>
      )}
    </FormControl>
  );
}

export default withTheme(InputTags);
