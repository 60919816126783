import React, { useState } from "react";
import { CustomText, Input, InputDate } from "../../../components/index";
import { Grid } from "@material-ui/core";
import { masks, validations } from "../../../utils";
import { fonts, Spacing } from "../../../config";
import Styles from "../styles/Styles";
import { FormFull } from "form-full";
import { customModal } from "../../../components/modals/utils";
import { AccessTime } from "@material-ui/icons";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import '../../../phone.css'
import { LabelView } from "../../../components/inputs/Input";
import { MyLabel } from "../../registryReservation/components/ModalRegistry";


function ModalRegistry({ data, onSubmit }: { data?: any; onSubmit?: any }) {
  const phone = React.useRef<any>()
  function setPhone(value){
    phone.current = value;
  }
  function handleSubmit(data) {
    data.phone = phone.current+"";
  }

  React.useEffect(()=>{
    if(data){
      setPhone(data.phone);
    }
  },[]);
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          minWidth: "clamp(300px, 20vw, 650px)",
          paddingInline: "25px",
        }}
      >
        <FormFull onSubmit={handleSubmit}>
          <CustomText
            style={{ marginBottom: "20px" }}
            fontFamily={fonts.bold}
            fontSize={14 / 8}
            textColor="#B2B2B2"
          >
            DADOS DO RESPONSÁVEL E PERÍODO
          </CustomText>
          <Grid alignItems="flex-end" container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <Input
                name="personName"
                label="NOME COMPLETO"
                defaultValue={data?.personName || ""}
                validation={validations.isValidFullname}
                required={"*Campo Obrigatório"}
                white
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Input
                required={"*Campo Obrigatório"}
                name="cpf"
                defaultValue={data?.cpf || ""}
                validation={validations.validateCPF}
                mask={masks.CPF}
                maskToSubmit={masks.removeNumberMask}
                label="CPF"
                white
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Input
                name="email"
                label="E-mail"
                defaultValue={data?.email || ""}
                validation={validations.isEmailValid}
                required={"*Campo Obrigatório"}
                white
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <LabelView>
                <MyLabel
                  white
                  className={"form-input-label-" + "telefone"?.replace(/\s/g, "")}
                >
                  {"Telefone*"}
                </MyLabel>
              </LabelView>
              <div style={{backgroundColor:"#E9E9E9", height:"48px", alignContent:"center", border:"0px", borderRadius:"5px", padding:"5px"}}>
              <PhoneInputWithCountrySelect
                placeholder="+55 99 99999-9999"
                numberInputProps={{className:"focus:outline-none focus:ring-0",}}
                value={phone.current}
                onChange={setPhone}
              />
              </div>
              {/*
              <Input
                required={"*Campo Obrigatório"}
                mask={masks.inputMaskTELWithDDD}
                name="phone"
                label="Telefone"
                defaultValue={data?.phone || ""}
                white
                maskToSubmit={masks.removeNumberMask}
              />
              */}
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <InputDate
                label="DATA"
                onBlur={() => null}
                onChange={() => null
                }
                name="date"
                customValidation={() => null}
                defaultValue={data?.date || ""}
                minDate={new Date()}
                required={"*Campo Obrigatório"}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Input
                onBlur={() => null}
                mask={masks.formatHour}
                placeholder="00:00"
                validation={validations.validateTimeRange}
                name="time"
                label="HORÁRIO"
                defaultValue={data?.time || ""}
                endAdornment={<AccessTime style={{ fontSize: Spacing(2) }} />}
                required={"*Campo Obrigatório"}
                white
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "12px",
              marginTop: "40px",
              width: "100%",
            }}
          >
            <Styles.CancelButton
              fullWidth={false}
              onClick={() => {
                customModal.close();
              }}
            >
              Cancelar
            </Styles.CancelButton>
            <Styles.RegitryButton fullWidth={false} action="submit">
              Confirmar {data ? "Edição" : "Agendamento"}
            </Styles.RegitryButton>
          </div>
        </FormFull>
      </div>
    </>
  );
}

export default ModalRegistry;
