import React from "react";

import ptBr from "../../../config/texts/pt-br";
import {
  ButtonText,
  CustomText,
  Input,
  InputAutocomplete,
  InputDate,
} from "../../../components/index";
import Styles from "../styles";
import { FormFull } from "form-full";
import { masks } from "../../../utils";

import { fonts, Spacing, SVG } from "../../../config";
import { Grid } from "@material-ui/core";
import { customModal } from "../../../components/modals/utils";
import { Visibility } from "@material-ui/icons";

function ModalView({ item }) {
  const texts = ptBr.login;

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          minWidth: "clamp(250px, 26vw, 500px)",
          paddingInline: "25px",
          marginBottom: "20px",
        }}
      >
        <Grid alignItems="flex-end" container spacing={1}>
          <Grid item xs={12} md={6} lg={6}>
            <Styles.Label>IMÓVEL</Styles.Label>
            <Styles.Value>{item?.place?.name || item?.name}</Styles.Value>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Styles.Label>ID</Styles.Label>
            <Styles.Value>{item?.place?.id || item?.id }</Styles.Value>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Styles.Label>UF</Styles.Label>
            <Styles.Value>{item?.place?.address?.state ||item?.address?.state }</Styles.Value>
          </Grid>{" "}
          <Grid item xs={12} md={6} lg={6}>
            <Styles.Label>CIDADE</Styles.Label>
            <Styles.Value>{item?.place?.address?.city || item?.address?.city }</Styles.Value>
          </Grid>{" "}
          <Grid item xs={12} md={6} lg={6}>
            <Styles.Label>CEP</Styles.Label>
            <Styles.Value>{masks.CEP(item?.place?.address?.cep ||item?.address?.cep) }</Styles.Value>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Styles.Label>LOGRADOURO</Styles.Label>
            <Styles.Value>{item?.place?.address?.street || item?.address?.street }</Styles.Value>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Styles.Label>BAIRRO</Styles.Label>
            <Styles.Value>{item?.place?.address?.district || item?.address?.district }</Styles.Value>
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <Styles.Label>Nº</Styles.Label>
            <Styles.Value>{item?.place?.address?.number ||  item?.address?.number }</Styles.Value>
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <Styles.Label>COMPLEMENTO</Styles.Label>
            <Styles.Value>{item?.place?.address?.complement || item?.address?.complement }</Styles.Value>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default ModalView;
