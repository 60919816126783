import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ptBr from "../../config/texts/pt-br";
import {
  ButtonContained,
  ButtonMenu,
  ButtonOutlined,
  ButtonText,
  ContentFeedback,
  CustomText,
  Input,
  InputAutocomplete,
  Pagination,
  Table,
} from "../../components/index";
import Styles from "./styles";
import { FormFull } from "form-full";
import { masks, validations } from "../../utils";
import { hooks, SessionStorage } from "../../utils";
import { StorageContext } from "../../contexts/StorageContext";
import { BsPersonFillSlash } from "react-icons/bs";
import { api } from "../../services";
import { paths } from "../../navigation/navigate";
import { fonts, Spacing, SVG } from "../../config";
import { Grid } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import { Close, ExpandMore, Mail, VisibilityOff } from "@material-ui/icons";
import { customModal } from "../../components/modals/utils";
import ModalView from "./components/ModalView";
import ModalVisitorAccess from "./components/ModalVisitorAccess";
import Filters from "./components/Filters";
import alerts from "../../utils/alerts";
import ModalAprove from "../registryReservation/components/ModalAprove";
import ModalVisitorRevokeAccess from "./components/ModalVisitorRevokeAccess";

const PAGE_SIZE = 8;

function MyProperties() {
  const texts = ptBr.login;
  const { search } = useLocation();

  const navigate = useNavigate();
  const { loading, call } = hooks.useRequest();
  const { addData, setIsLogged } = useContext<any>(StorageContext);
  const [page, setPage] = React.useState<number>(0);
  const [filters, setFilters] = React.useState<any>(null);
  const [places, setPlaces] = React.useState<any>(null);
  const [isMounted, setMount] = React.useState<boolean>(false);

  const Mount = React.useCallback(() => {
    call(
      null,
      api.getPlaces({
        page: 0,
        size: PAGE_SIZE,
      }),
      async (response) => {
        if (response.ok) {
          setPlaces(response?.data);
        }
      }
    );
  }, []);

  React.useEffect(() => {
    if (!isMounted) {
      Mount();
      setMount(true);
    }
  }, [Mount, isMounted]);

  const Submit = async (
    filterData: { name?: string; reservationStatus?: string },
    page?: number
  ) => {
    let filters = {
      page: page ? page : 0,
      size: PAGE_SIZE,
    };

    if (filterData?.name) {
      filters["name"] = filterData.name;
    }
    if (filterData?.reservationStatus) {
      filters["reservationStatus"] = filterData.reservationStatus;
    }

    call(null, api.getPlaces(filters), async (response) => {
      if (response.ok) {
        setPlaces(response?.data);
        setPage(page ? page : 0);
        setFilters(filterData);
      }
    });
  };

  const openModalView = (item: any) => {
    call(null, api.getPlaceData(item.id), async (response) => {
      if (response.ok) {
        customModal.setInfos(
          "Visualizar Dados do Imóvel",
          [],
          {
            onClick: () => {
              customModal.close();
            },
            label: "Voltar",
          },
          null,
          <ModalView item={response.data} />,
          false,
          true,
          <Visibility />
        );
      }
    });
  };

  const openModalVisitorAccess = (item: any) => {
    call(null, api.getPlaceData(item.id), async (response) => {
      if (response.ok) {
        customModal.setInfos(
          "Conceder Acesso a Visitante",
          [
            
          ],
          null,
          null,
          <ModalVisitorAccess item={{ place: response.data }}/>,
          false,
          true,
          <Styles.VisitorIcon $customColor />
        );
      }
    });
  };

  const RevokeVisitorAccess = (item: any) => {
    call(null, api.getPlaceData(item.id), async (response) => {
      if (response.ok) {
        /*
        customModal.setInfos(
          "Revogar Acesso Visitante",
          ["Continuar e revogar acesso deste visitante?"],
          {
            onClick: () => {
              customModal.close();
            },
            label: "Sim, Revogar",
          },
          {
            onClick: () => {
              customModal.close();
            },
            label: "Voltar",
          },
          null,
          null,
          null,
          <BsPersonFillSlash />
        );*/
        customModal.setInfos(
          "Revogar Acesso Visitante",
          [
            
          ],
          null,
          null,
          <ModalVisitorRevokeAccess item={{ place: response.data }}/>,
          false,
          true,
          <Styles.VisitorIcon $customColor />
        );
      }
    });
  };

  const getStatus = (status: string) => {
    if (status === "FP") {
      return "Foto Pendente";
    } else if (status === "PA") {
      return "Aguardando Liberação";
    } else if (status === "AL") {
      return "Acesso Liberado";
    } else if (status === "AF") {
      return "Acesso Finalizado";
    } else if (status === "AR") {
      return "Acesso Recusado";
    } else if (status === "AC") {
      return "Acesso Cancelado";
    } else {
      return "-";
    }
  };

  const revokeAccess = (id: string) => {
    call(null, api.patchReservationRevoke(id), (response) => {
      if (response.ok) {
        Mount();
      }
    });
  };

  const sendEmail = (item: any) => {
    customModal.setInfos(
      "Reenviar E-mail",
      [],
      null,
      null,
      <ModalAprove
        email
        page={page}
        item={item}
        reload={(value) => Submit(filters, value)}
      />,
      false,
      true,
      <Mail />
    );
  };

  const deniedAccess = (id: string) => {
    call(null, api.patchDeniedAccess({ reservationId: id }), (response) => {
      if (response.ok) {
        Submit(filters, page);
        customModal.setInfos(
          "Recusar Reserva",
          ["Reserva recusada com sucesso!"],
          {
            label: "Ok, Entendi",
            onClick: () => {
              customModal.close();
            },
          },
          null,
          null,
          false,
          true,
          <Close />
        );
      }
    });
  };

  const getButtons = (status: string, item: any): any[] => {
    if (status === "PA" || status === "FP") {
      return [
        {
          label: "Visualizar Dados do Imóvel",
          icon: Visibility,
          onClick: () => {
            openModalView(item);
          },
        },
      ];
    } else if (status === "AL") {
      return [
        {
          label: "Visualizar Dados do Imóvel",
          icon: Visibility,
          onClick: () => {
            openModalView(item);
          },
        },
        {
          label: "Reenviar E-mail",
          icon: Mail,
          onClick: () => {
            sendEmail(item);
          },
        },
        {
          label: "Revogar Acesso",
          icon: BsPersonFillSlash,
          onClick: () => {
            customModal.setInfos(
              "Revogar Acesso",
              [
                "Ao revogar o acesso deste imóvel, o usuário vinculado com o mesmo terá sua chave atual desativada, porém sua compra referente a este imóvel continuará ativa. Continuar e revogar acesso?",
              ],
              {
                onClick: () => {
                  revokeAccess(item.currentReservationId);
                  customModal.close();
                },
                label: "Sim, Revogar",
              },
              {
                onClick: () => {
                  customModal.close();
                },
                label: "Voltar",
              },
              null,
              null,
              null,
              <BsPersonFillSlash />
            );
          },
        },
        {
          label: "Conceder Acesso a Visitante",
          icon: Styles.VisitorIcon,
          onClick: () => {
            openModalVisitorAccess(item);
          },
        },
        {
          label: "Revogar Acesso Visitante",
          icon: BsPersonFillSlash,
          onClick: () => {
            RevokeVisitorAccess(item);
          },
        },
      ];
    } else if (status === "AF") {
      return [
        {
          label: "Visualizar Dados do Imóvel",
          icon: Visibility,
          onClick: () => {
            openModalView(item);
          },
        },
      ];
    } else if (status === "AR") {
      return [
        {
          label: "Visualizar Dados do Imóvel",
          icon: Visibility,
          onClick: () => {
            openModalView(item);
          },
        },
      ];
    } else if (status === "AC") {
      return [
        {
          label: "Visualizar Dados do Imóvel",
          icon: Visibility,
          onClick: () => {
            openModalView(item);
          },
        },
      ];
    } else {
      return [
        {
          label: "Visualizar Dados do Imóvel",
          icon: Visibility,
          onClick: () => {
            openModalView(item);
          },
        },/*
        {
          label: "Conceder Acesso a Visitante",
          icon: Styles.VisitorIcon,
          onClick: () => {
            openModalVisitorAccess(item);
          },
        },
        {
          label: "Revogar Acesso Visitante",
          icon: BsPersonFillSlash,
          onClick: () => {
            RevokeVisitorAccess(item);
          },
        },*/
      ];
    }
  };

  return (
    <>
      <Styles.Container>
        <CustomText
          style={{ paddingBottom: "8px" }}
          fontSize={2}
          textColor="#4D585A"
          fontFamily={fonts.bold}
        >
          Filtros
        </CustomText>
        <Filters onSubmit={Submit} loading={loading} />
      </Styles.Container>

      <Styles.Container noLine>
        <CustomText
          style={{ padding: "16px 20px" }}
          fontSize={2}
          textColor="#4D585A"
          fontFamily={fonts.bold}
        >
          Lista de Imóveis
        </CustomText>
        <ContentFeedback data={places?.content} loading={loading}>
          <Table
            lastAlign={false}
            data={places?.content}
            renderItemColumns={(item) => [
              item.name,
              item.currentStartDateReservation ? (
                <CustomText style={{ gap: "3px" }}>
                  <b>{masks.formatDate(item.currentStartDateReservation)} </b> a
                  <b>{masks.formatDate(item.currentEndDateReservation)}</b>
                </CustomText>
              ) : (
                "-"
              ),
              item.currentStartDateReservation ? (
                <CustomText style={{ gap: "3px" }}>
                  <b>{masks.extractHour(item.currentStartDateReservation)} </b>
                </CustomText>
              ) : (
                "-"
              ),
              item.currentEndDateReservation ? (
                <CustomText style={{ gap: "3px" }}>
                  <b>{masks.extractHour(item.currentEndDateReservation)}</b>
                </CustomText>
              ) : (
                "-"
              ),
              <b>{getStatus(item.currentReservationStatus)}</b>,
              <ButtonMenu
                endIcon={<ExpandMore />}
                buttons={getButtons(item.currentReservationStatus, item)}
              >
                Opções
              </ButtonMenu>,
            ]}
            headers={{
              table: ["IMÓVEL", "ESTADIA ATUAL", "CHECK-IN", "CHECK-OUT", "STATUS", "AÇÃO"],
              keys: [],
            }}
          />
          <Pagination
            style={{ padding: "16px 20px", paddingTop: 0 }}
            page={page + 1}
            totalPages={places?.totalPages}
            setPage={(number) => {
              Submit(filters, number - 1);
            }}
          />
        </ContentFeedback>
      </Styles.Container>
    </>
  );
}

export default MyProperties;
